/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

interface MultipleProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Text
}

const Multiple: React.FC<MultipleProps> = ({ fields }) => (
  <section className="py-lg-5 py-4">
    <div className="container py-lg-5">
      <div className={`row justify-content-${fields.alignment}`}>
        {fields.multipletext?.map((m, index) => (
          <div key={index} className={`col-lg-${m?.width}`}>
            <ParseContent content={m?.description || ''} />
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Multiple
